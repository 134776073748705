.label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 67px;
  min-height: 28px;
  height: min-content;
  padding: 8px 13.23px 8px 12px;

  border-radius: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  letter-spacing: 2px;
  text-transform: uppercase;

  /* accent */

  &.primary {
    color: #fd4367;
    background: rgba(253, 67, 103, 0.1);
  }
  &.seccondary {
    color: #00a81f;
    background-color: #6ffc8963;
  }
  &.tool {
    color: rgb(125 66 251);
    background-color: rgba(125, 66, 251, 0.431);
  }
  &.tool2 {
    color: white;
    background-color: rgb(125 66 251);
  }
}

.labelbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 18px;
  width: 100%;
}
