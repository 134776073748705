.outerbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: min-content;
}

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;

  width: 100%;
  height: 55px;

  /* light / 300 */

  border: 2px solid #f3f2f4;
  border-radius: 12px;
}

.input {
  font-family: 'poppins-regular', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  /* or 27px */

  display: flex;
  align-items: center;
  width: 100%;
  /* placeholder */
  outline: none;
  color: #6c7892;
}
