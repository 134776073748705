.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: clamp(20px, 4vw, 40px);
  gap: 10px;

  width: 100%;
  height: min-content;

  max-width: 481px;

  background: #ffffff;
  border-radius: 36px;
  gap: 60px;
}

.title {
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  align-self: stretch;
  flex-grow: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  /* Title */

  color: #201e57;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 19px;
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 15px;
  width: 100%;
  .main {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 15px;
    width: 100%;
  }
  .sub {
    flex-grow: 1;
    width: 100%;
  }
}
